@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root,
.scroll {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-snap-type: y proximity;
}

.scroll > div {
  scroll-snap-align: start;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
}

.caption {
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  color: black;
  margin: 80px;
  font-size: 8em;
  font-weight: 600;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

.dot {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  position: sticky;
  float: right;
  top: 0px;
  display: block;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  line-height: 1.6em;
  font-size: 16px;
  letter-spacing: 1.5px;
}


.dot >p{
  font-family: 'Roboto', sans-serif;
}

.dot >p >button{
  height: 100%;
  font-weight: bold;
  
}
/* CSS */
.button-21 {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 10px) calc(100% + 10px);
  border-radius: 100px;
  border-width: 0;
  left: -8px;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-weight: lighter;
  font-size: 0.8rem;
  height: auto;
  justify-content: center;
  line-height: 1;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -10px -10px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.button-21 > a{
  color: white;
  text-decoration: none;  
}
.dotContact{
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  position: sticky;
  float: left;
  top: 0px;
  display: block;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  line-height: 1.6em;
  font-size: 16px;
  letter-spacing: 1.5px;
}

.dotContact > h1{
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: black;
  font-size: 5em;
  font-weight: 600;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
}

.dotContact > p{
  font-family: 'Roboto', sans-serif;
  color: black;
  font-weight: 300;
}


.dotSpecial > h1{
  position: absolute;
  color: #757575;
  top: 60%;
  left: 50%;
  translate: -40% -50%;
  display: inline-block;
  max-width: 600px;
  text-align: center;
  font-family: "Inter" sans-serif;
  line-height: 1.6em;
  font-size: 1em;
  font-weight: 400;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: black;
  font-size: 5em;
  font-weight: 600;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;

}

.dot p{
  font-family: 'Roboto', sans-serif;
  color: black;
  margin-left: 6px;
  opacity: 1;
  font-weight: 200;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  .caption {
    font-size: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .caption {
    font-size: 3em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 700px) {
  .caption {
    font-size: 2em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dotContact > h1{
    font-size: 3em;
  }
}

@media only screen and (max-width: 600px) {
  .caption {
    font-size: 1em;
  }
  .dot{
    padding: 30px;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dotContact > h1{
    font-size: 3em;
  }
}

@keyframes example {
  0%   {opacity: 0; left:50%; top:60%; }
  50%  {opacity: 1; left: 50%; top:65%; }
  100%  {opacity: 0; left:50%; top:60%;}
}